<template>
  <div v-if="!isLoading" class="page-wrapper">
    <div class="page-header">
      <div class="container">
        <div class="page-header-inner">
          <button class="button button--rounded" @click="$router.back()">
            <i class="ri-arrow-left-s-line"></i>Back
          </button>
          <div class="invoice__wrapper">
            <div class="invoice__main">
              <div class="invoice__date-wrapper">
                <div class="invoice__date">
                  <i class="ri-calendar-fill"></i>
                  {{ moment(invoice.dateFrom).format('MMM DD') }} – {{ moment(invoice.dateTill).format('MMM DD') }}
                </div>
              </div>
              <button class="icon-button icon-button--round">
                <a :href="invoice.invoicePdfURL" target="_blank"><i class="ri-arrow-down-circle-fill"></i></a>
              </button>
              <button v-if="invoice.status === 'unpaid'" class="button button--fill button--rounded">
                <a :href="invoice.invoicePayURL" target="_blank">Pay</a>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page-main">
      <div class="container">
        <div class="orders__wrapper">
          <Order v-for="order of invoice.deliveryOrders" :key="order.id" :order="order" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import moment from 'moment'
import Order from '../Order'

export default {
  name: 'EnterpriseInvoisePage',
  components: {Order},
  data() {
    return {
      moment,
      isLoading: true
    }
  },
  computed: {
    ...mapState({
      invoice: s => s.invoices.invoice
    })
  },
  async created() {
    const invoiceID = this.$route.params.id
    if (invoiceID != this.invoice?.id) {
      await this.fetch(invoiceID)
      this.isLoading = false
    } else {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      fetch: 'invoices/fetchInvoice'
    })
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
<style lang="scss">
@import 'src/assets/styles/_variables.scss';

.order__image-error {
  background-color: $grey_border_light;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
